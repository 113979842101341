<script setup>
import Header from './components/SddsHeader';
import SddsToastWrapper from './components/SddsToastWrapper';
import { ref, inject, computed } from 'vue';
import { useMainStore } from './store';
import { useRoute } from 'vue-router';

const adminRole = process.env.VUE_APP_DS_KEYCLOAK_ADMIN_ROLE;
const superUserRole = process.env.VUE_APP_DS_KEYCLOAK_SUPER_USER;
const frontendClient = process.env.VUE_APP_DS_KEYCLOAK_MAIN_FRONTEND_CLIENT;

const keycloak = inject('keycloak');
const store = useMainStore();
const route = useRoute();

const isAdmin = ref(keycloak.hasResourceRole(adminRole, frontendClient));
const isSuperUser = ref(keycloak.hasResourceRole(superUserRole, frontendClient));

store.loadTranslations();

const updateToken = () => {
  keycloak.updateToken(90).then(() => {
    isAdmin.value = keycloak.hasResourceRole(adminRole, frontendClient);
    isSuperUser.value = keycloak.hasResourceRole(superUserRole, frontendClient);

    if (!isAdmin.value && !isSuperUser.value) {
      const logoutOptions = { redirectUri: window.location.origin };
      keycloak.logout(logoutOptions).then((success) => {
        console.log('--> log: logout success ', success);
      }).catch((error) => {
        console.log('--> log: logout error ', error);
      }).finally(() => {
        return false;
      });
    }
    store.token = keycloak.token;
    return true;
  }).catch(() => {
    // handle this in some way
    return false;
  });
};

// Token lives for 5 minutes, refresh token lives for 1 hour, session lasts for 10 hours.
// Update token every 4 minutes to avoid any potential issues
const updateTokenIntervalHandle = setInterval(updateToken, 240000);

const adminMenuItems = ref([
  { name: 'ConvertView', text: 'Excel converter' },
  { name: 'UploadStaticImageView', text: 'Upload static image' },
  { name: 'FeatureTogglesOverviewView', text: 'Feature Toggle' },
  {
    text: 'Master offers',
    subMenuItems: [
      { name: 'MasterOffersOverviewView', text: 'Master offers overview' },
      { name: 'CreateMasterOfferView', text: 'Create Master offer' },
    ],
    subMenuOpen: false,
  },
  {
    text: 'Offers',
    subMenuItems: [
      { name: 'OfferSpecValidateView', text: 'Validate specification' },
      { name: 'GuidedOfferCreationView', text: 'Create offer'},
      { name: 'FinalizationView', text: 'Finalizations' },
      { name: 'AddISSpecificationView', text: 'Add IS Specification' },
      { name: 'ResetStateView', text: 'Reset state' },
      { name: 'OfferCommentsView', text: 'Offer comments' },
      { name: 'OfferingsOverviewView', text: 'Offerings overview' },
      { name: 'OffersOverviewV2View', text: 'Offers overview v2' },
    ],
    subMenuOpen: false,
  },
  {
    text: 'Users',
    subMenuItems: [
      { name: 'UserOverviewViewV2', text: 'Overview' },
      { name: 'AddUsersView', text: 'Add new users' },
    ],
    subMenuOpen: false,
  },
  {
    text: 'Batch process',
    subMenuItems: [
      { name: 'BatchOverviewView', text: 'Batch overview' },
      { name: 'CreateBulkOffersView', text: 'Create bulk offers' },
    ],
    subMenuOpen: false,
  },
  {
    text: 'Contact Requests',
    subMenuItems: [
      { name: 'ContactRequestsOverview', text: 'Overview' },
    ],
    subMenuOpen: false,
  },
  {
    text: 'Vehicle Models',
    subMenuItems: [
      { name: 'VehicleModelsOverviewView', text: 'Overview' },
      { name: 'AddVehicleModelConfigurationView', text: 'Add vehicle models configuration' },
    ],
    subMenuOpen: false,
  },
  {
    text: 'Sales Representative',
    subMenuItems: [
      { name: 'SalesRepOverviewView', text: 'Overview' },
      { name: 'AddSalesRepView', text: 'Add Sales Representative' },
    ],
    subMenuOpen: false,
  },
  {
    text: 'Campaign Emails',
    subMenuItems: [
      { name: 'CampaignEmailsOverview', text: 'Overview' },
      { name: 'SendCampaignEmailsView', text: 'Send Campaign Emails' },
    ],
    subMenuOpen: false,
  },
]);

const superUserMenuItems = ref([
  {
    text: 'Offers',
    subMenuItems: [
      { name: 'FinalizationView', text: 'Finalizations' },
      { name: 'OfferingsOverviewView', text: 'Offerings overview' },
      { name: 'OffersOverviewV2View', text: 'Offers overview v2' },
    ],
    subMenuOpen: false,
  },
]);

const isSubMenu = (item) => {
  return item?.subMenuItems?.length > 0;
};

const hasSubMenuItem = (item, name) => {
  return item?.subMenuItems?.find(x => x.name === name);
};

const menuItems = computed(() => {
  return isAdmin.value ? adminMenuItems.value : superUserMenuItems.value;
});

const activeSidemenuItemName = computed(() => {
  return route.name;
});

</script>

<template>
  <sdds-theme />
  <Header
    title="Digital Sales Admin Tool"
  />
  <div class="sdds-push">
    <div class="sdds-sidebar side-menu">
      <ul class="sdds-sidebar-nav sdds-sidebar-nav--main icons-disabled">
        <li
          v-for="item in menuItems"
          :key="item.name"
          class="sdds-sidebar-nav__item"
          :class="{ 'sdds-item--active': activeSidemenuItemName === item.name || (hasSubMenuItem(item, activeSidemenuItemName) && !item.subMenuOpen), 'sdds-sidebar-nav__extended': isSubMenu(item), 'subnav-open': item.subMenuOpen }"
        >
          <a
            v-if="isSubMenu(item)"
            href="#"
            class="sdds-sidebar-nav__item-link"
            @click.prevent="item.subMenuOpen = !item.subMenuOpen"
          >
            <span class="sdds-sidebar-nav__item-title">{{ item.text }}</span>
            <svg
              v-if="isSubMenu(item)"
              class="sdds-sidebar-nav__chevron"
              width="12"
              height="7"
              viewBox="0 0 12 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L6 6L11 1"
                stroke="currentColor"
                stroke-width="1.25"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </a>
          <router-link
            v-else
            class="sdds-sidebar-nav__item-link"
            :to="{ name: item.name }"
          >
            <span class="sdds-sidebar-nav__item-title">{{ item.text }}</span>
          </router-link>
          <ul
            v-if="isSubMenu(item)"
            class="sdds-sidebar-nav-subnav"
          >
            <li class="sdds-sidebar-nav-subnav__item">
              <span class="sdds-sidebar-nav__item-title">{{ item.text }}</span>
            </li>
            <li
              v-for="subItem in item.subMenuItems"
              :key="`subitem-${subItem.name}`"
              class="sdds-sidebar-nav-subnav__item"
              :class="{ 'sdds-item--active': activeSidemenuItemName === subItem.name }"
            >
              <router-link
                class="sdds-sidebar-nav__item-link"
                :to="{ name: subItem.name }"
              >
                <span class="sdds-sidebar-nav__item-text">{{ subItem.text }}</span>
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="sdds-container superpower">
      <router-view />
      <div class="sdds-toast-wrapper">
        <SddsToastWrapper />
      </div>
    </div>
  </div>
</template>

<style>
html {
  height: 100%;
  background-color: var(--sdds-grey-50);
}
.superpower {
  min-height: calc(100vh - 64px);
}
.sdds-toast-wrapper {
    position: fixed;
    bottom: 0.5em;
    right: 0.5em;
}
.sdds-textfield-input {
  border-bottom: 1px solid var(--sdds-textfield-border-bottom);
  transition: border-bottom-color 200ms ease 0s;
}
.sdds-textfield-input:hover {
  border-bottom: 1px solid var(--sdds-textfield-border-bottom-hover);
}

span.pill {
  --bg-color: var(--sdds-blue-400);
  --text-color: var(--sdds-white);
  background-color: var(--bg-color);
  color: var(--text-color);
  font-family: var(--sdds-font-family-semi-condensed-bold);
  font-size: var(--sdds-badges-size-text);
  font-weight: bold;
  border-radius: 2rem;
  padding: 0.7rem 1.4rem;
  margin-right: 1rem;
}

.content--center {
  display: flex;
  place-content: center;
}

.margin-top-16 { margin-top: var(--sdds-spacing-layout-16); }
.margin-top-24 { margin-top: var(--sdds-spacing-layout-24); }
.margin-top-32 { margin-top: var(--sdds-spacing-layout-32); }
.margin-top-48 { margin-top: var(--sdds-spacing-layout-48); }
.margin-top-64 { margin-top: var(--sdds-spacing-layout-64); }
.margin-top-96 { margin-top: var(--sdds-spacing-layout-96); }

.margin-bottom-16 { margin-bottom: var(--sdds-spacing-layout-16); }
.margin-bottom-24 { margin-bottom: var(--sdds-spacing-layout-24); }
.margin-bottom-32 { margin-bottom: var(--sdds-spacing-layout-32); }
.margin-bottom-48 { margin-bottom: var(--sdds-spacing-layout-48); }
.margin-bottom-64 { margin-bottom: var(--sdds-spacing-layout-64); }
.margin-bottom-96 { margin-bottom: var(--sdds-spacing-layout-96); }

.padding-top-16 { padding-top: var(--sdds-spacing-layout-16); }
.padding-top-24 { padding-top: var(--sdds-spacing-layout-24); }
.padding-top-32 { padding-top: var(--sdds-spacing-layout-32); }
.padding-top-48 { padding-top: var(--sdds-spacing-layout-48); }
.padding-top-64 { padding-top: var(--sdds-spacing-layout-64); }
.padding-top-96 { padding-top: var(--sdds-spacing-layout-96); }

.padding-left-16 { padding-left: var(--sdds-spacing-layout-16); }
.padding-left-24 { padding-left: var(--sdds-spacing-layout-24); }
.padding-left-32 { padding-left: var(--sdds-spacing-layout-32); }
.padding-left-48 { padding-left: var(--sdds-spacing-layout-48); }
.padding-left-64 { padding-left: var(--sdds-spacing-layout-64); }
.padding-left-96 { padding-left: var(--sdds-spacing-layout-96); }

.el-margin-left-8 { margin-left: var(--sdds-spacing-element-8); }
.el-margin-left-16 { margin-left: var(--sdds-spacing-element-16); }

.el-margin-right-8 { margin-right: var(--sdds-spacing-element-8); }
.el-margin-right-16 { margin-right: var(--sdds-spacing-element-16); }

.el-margin-top-8 { margin-top: var(--sdds-spacing-element-8); }
.el-margin-top-16 { margin-top: var(--sdds-spacing-element-16); }

.el-margin-bottom-8 { margin-bottom: var(--sdds-spacing-element-8); }
.el-margin-bottom-16 { margin-bottom: var(--sdds-spacing-element-16); }
</style>
