import { createRouter as createVueRouter, createWebHistory } from 'vue-router';
// import HomeView from './views/HomeView';
// import ConvertExcelView from './views/ConvertExcelView';
// import UploadStaticImageView from './views/UploadStaticImageView';
// import ResetStateView from './views/ResetStateView';
// import SuperUserView from './views/SuperUserView';
// import FinalizationView from './views/FinalizationView';
// import UserOverviewView from './views/UserOverviewView';
// import UserAdministrationView from './views/UserAdministrationView';
// import AddISSpecificationView from './views/AddISSpecificationView';
// import OfferOverviewView from './views/OfferOverviewView';
// import OfferAdministrationView from './views/OfferAdministrationView';
// import AddUsersView from './views/AddUsersView';
// import SearchUsersByStaffReferencesView from './views/SearchUsersByStaffReferencesView';

import { useMainStore } from '@/store';

const HomeView = () => import('./views/HomeView');
const ConvertExcelView = () => import('./views/ConvertExcelView');
const UploadStaticImageView = () => import('./views/UploadStaticImageView');
const ResetStateView = () => import('./views/ResetStateView');
const FinalizationView = () => import('./views/FinalizationView');
const UserOverviewView = () => import('./views/UserOverviewView');
const UserAdministrationView = () => import('./views/UserAdministrationView');
const AddISSpecificationView = () => import('./views/AddISSpecificationView');
const OfferAdministrationView = () => import('./views/OfferAdministrationView');
const AddUsersView = () => import('./views/AddUsersView');
const GuidedOfferCreationView = () => import('./views/GuidedOfferCreationView');
const OfferSpecValidateView = () => import('./views/OfferSpecValidateView');
const BatchOverviewView = () => import('./views/BatchOverviewView');
const CreateBulkOffersView = () => import('./views/CreateBulkOffersView');
const AddVehicleModelConfigurationView = () => import('./views/AddVehicleModelConfigurationView');
const VehicleModelsOverviewView = () => import('./views/VehicleModelsOverviewView');
const UpdateVehicleModelView = () => import('./views/UpdateVehicleModelView');
const SalesRepOverviewView = () => import('./views/SalesRepOverviewView');
const AddSalesRepView = () => import('./views/AddSalesRepView');
const UpdateSalesRepView = () => import('./views/UpdateSalesRepView');
const MasterOffersOverviewView = () => import('./views/MasterOffersOverviewView');
const CreateMasterOfferView = () => import('./views/CreateMasterOfferView');
const MasterOfferAdministrationView = () => import('./views/MasterOfferAdministrationView');
const OfferCommentsView = () => import('./views/OfferCommentsView');
const OfferingsOverviewView = () => import('./views/OfferingsOverviewView');
const SendCampaignEmailsView = () => import('./views/SendCampaignEmailsView');
const CampaignEmailsOverview = () => import('./views/CampaignEmailsOverview');
const OffersOverviewV2View = () => import('./views/OffersOverviewV2View');
const UserOverviewViewV2 = () => import('./views/UserOverviewViewV2.vue');
const FeatureTogglesOverviewView = () => import('./views/FeatureTogglesOverviewView');
const FeatureToggleAdministrationView = () => import('./views/FeatureToggleAdministrationView');
const FeatureToggleCreationView = () => import('./views/FeatureToggleCreationView');
const ContactRequestsOverview = () => import('./views/ContactRequestsOverview');

const adminRole = process.env.VUE_APP_DS_KEYCLOAK_ADMIN_ROLE;
const superUserRole = process.env.VUE_APP_DS_KEYCLOAK_SUPER_USER;
const frontendClient = process.env.VUE_APP_DS_KEYCLOAK_MAIN_FRONTEND_CLIENT;

const isAdmin = (app) => {
  return app.$keycloak.authenticated && app.$keycloak.hasResourceRole(adminRole, frontendClient);
};

const isSuperUser = (app) => {
  return app.$keycloak.authenticated && app.$keycloak.hasResourceRole(superUserRole, frontendClient);
};

const createRouter = (app) => {
  const router = createVueRouter({
    history: createWebHistory(),
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      } else {
        return { top: 0 };
      }
    },
    routes: [
      {
        path: '/',
        name: 'Home',
        component: HomeView,
        beforeEnter: (to, from) => {
          if(isAdmin(app)) {
            return ({ name: 'ConvertView' });
          }
          if(isSuperUser(app)) {
            return ({ name: 'SuperUserView' });
          }
        },
      },
      {
        path: '/convert',
        name: 'ConvertView',
        component: ConvertExcelView,
        beforeEnter: (to, from) => {
          if(!isAdmin(app)) {
            return ({ name: 'Home' });
          }
        },
      },
      {
        path: '/upload-static-image',
        name: 'UploadStaticImageView',
        component: UploadStaticImageView,
        beforeEnter: (to, from) => {
          if(!isAdmin(app)) {
            return ({ name: 'Home' });
          }
        },
      },
      {
        path: '/master-offers',
        name: 'MasterOffersOverviewView',
        component: MasterOffersOverviewView,
        beforeEnter: (to, from) => {
          if(!isAdmin(app)) {
            return ({ name: 'Home' });
          }
        },
      },
      {
        path: '/master-offers/create',
        name: 'CreateMasterOfferView',
        component: CreateMasterOfferView,
        beforeEnter: (to, from) => {
          if(!isAdmin(app)) {
            return ({ name: 'Home' });
          }
        },
      },
      {
        path: '/master-offers/:masterOfferId',
        name: 'MasterOfferAdministrationView',
        component: MasterOfferAdministrationView,
        beforeEnter: (to, from) => {
          if(!isAdmin(app)) {
            return ({ name: 'Home' });
          }
        },
      },
      {
        path: '/offers/comments',
        name: 'OfferCommentsView',
        component: OfferCommentsView,
        beforeEnter: (to, from) => {
          if(!isAdmin(app)) {
            return ({ name: 'Home' });
          }
        },
      },
      {
        path: '/offers/validate-spec',
        name: 'OfferSpecValidateView',
        component: OfferSpecValidateView,
        beforeEnter: (to, from) => {
          if(!isAdmin(app)) {
            return ({ name: 'Home' });
          }
        },
      },
      {
        path: '/offers/create',
        name: 'GuidedOfferCreationView',
        component: GuidedOfferCreationView,
        beforeEnter: (to, from) => {
          if(!isAdmin(app)) {
            return ({ name: 'Home' });
          }
        },
      },
      {
        path: '/offers/:marketId/:offerId',
        name: 'OfferAdministrationView',
        component: OfferAdministrationView,
        beforeEnter: (to, from) => {
          if(!isAdmin(app)) {
            return ({ name: 'Home' });
          }
        },
      },
      {
        path: '/users',
        name: 'UserOverviewView',
        component: UserOverviewView,
        beforeEnter: (to, from) => {
          if(!isAdmin(app)) {
            return ({ name: 'Home' });
          }
        },
      },
      {
        path: '/users/add',
        name: 'AddUsersView',
        component: AddUsersView,
        beforeEnter: (to, from) => {
          if(!isAdmin(app)) {
            return ({ name: 'Home' });
          }
        },
      },
      {
        path: '/users/:federationUserAccountId',
        name: 'UserAdministrationView',
        component: UserAdministrationView,
        beforeEnter: (to, from) => {
          if(!isAdmin(app)) {
            return ({ name: 'Home' });
          }
        },
      },
      {
        path: '/batch/overview',
        name: 'BatchOverviewView',
        component: BatchOverviewView,
        beforeEnter: (to, from) => {
          if(!isAdmin(app)) {
            return ({ name: 'Home' });
          }
        },
      },
      {
        path: '/batch/create-bulk-offers',
        name: 'CreateBulkOffersView',
        component: CreateBulkOffersView,
        beforeEnter: (to, from) => {
          if(!isAdmin(app)) {
            return ({ name: 'Home' });
          }
        },
      },
      {
        path: '/reset-state',
        name: 'ResetStateView',
        component: ResetStateView,
        beforeEnter: (to, from) => {
          if(!isAdmin(app)) {
            return ({ name: 'Home' });
          }
        },
      },
      {
        path: '/feature-toggle',
        name: 'FeatureTogglesOverviewView',
        component: FeatureTogglesOverviewView,
        beforeEnter: (to, from) => {
          if(!isAdmin(app)) {
            return ({ name: 'Home' });
          }
        },
      },
      {
        path: '/feature-toggle/:featureKey',
        name: 'FeatureToggleAdministrationView',
        component: FeatureToggleAdministrationView,
        beforeEnter: (to, from) => {
          if(!isAdmin(app)) {
            return ({ name: 'Home' });
          }
        },
      },
      {
        path: '/feature-toggle/create',
        name: 'FeatureToggleCreationView',
        component: FeatureToggleCreationView,
        beforeEnter: (to, from) => {
          if(!isAdmin(app)) {
            return ({ name: 'Home' });
          }
        },
      },
      {
        path: '/finalizations',
        name: 'FinalizationView',
        component: FinalizationView,
        beforeEnter: (to, from) => {
          if(!isAdmin(app) && !isSuperUser(app)) {
            return ({ name: 'Home' });
          }
        },
      },
      {
        path: '/add-is-specification',
        name: 'AddISSpecificationView',
        component: AddISSpecificationView,
        beforeEnter: (to, from) => {
          if(!isAdmin(app) && !isSuperUser(app)) {
            return ({ name: 'Home' });
          }
        },
      },
      {
        path: '/update-vehicle-models/:marketId/:modelId',
        name: 'UpdateVehicleModelView',
        component: UpdateVehicleModelView,
        beforeEnter: (to, from) => {
          if(!isAdmin(app)) {
            return ({ name: 'Home' });
          }
          const store = useMainStore();
          store.market = to.params.marketId;
        },
      },
      {
        path: '/add-vehicle-models',
        name: 'AddVehicleModelConfigurationView',
        component: AddVehicleModelConfigurationView,
        beforeEnter: (to, from) => {
          if(!isAdmin(app)) {
            return ({ name: 'Home' });
          }
        },
      },
      {
        path: '/vehicle-models-overview',
        name: 'VehicleModelsOverviewView',
        component: VehicleModelsOverviewView,
        beforeEnter: (to, from) => {
          if(!isAdmin(app)) {
            return ({ name: 'Home' });
          }
        },
      },
      {
        path: '/sales-reps-overview',
        name: 'SalesRepOverviewView',
        component: SalesRepOverviewView,
        beforeEnter: (to, from) => {
          if(!isAdmin(app)) {
            return ({ name: 'Home' });
          }
        },
      },
      {
        path: '/add-sales-reps',
        name: 'AddSalesRepView',
        component: AddSalesRepView,
        beforeEnter: (to, from) => {
          if(!isAdmin(app)) {
            return ({ name: 'Home' });
          }
        },
      },
      {
        path: '/update-sales-rep/:marketId/:salesRepId',
        name: 'UpdateSalesRepView',
        component: UpdateSalesRepView,
        beforeEnter: (to, from) => {
          if(!isAdmin(app)) {
            return ({ name: 'Home' });
          }
          const store = useMainStore();
          store.market = to.params.marketId;
        },
      },
      {
        path: '/offerings',
        name: 'OfferingsOverviewView',
        component: OfferingsOverviewView,
        beforeEnter: (to, from) => {
          if(!(isAdmin(app) || isSuperUser(app))) {
            return ({ name: 'Home' });
          }
        },
      },
      {
        path: '/send-campaign-emails',
        name: 'SendCampaignEmailsView',
        component: SendCampaignEmailsView,
        beforeEnter: (to, from) => {
          if(!isAdmin(app)) {
            return ({ name: 'Home' });
          }
        },
      },
      {
        path: '/campaign-emails',
        name: 'CampaignEmailsOverview',
        component: CampaignEmailsOverview,
        beforeEnter: (to, from) => {
          if(!isAdmin(app)) {
            return ({ name: 'Home' });
          }
        },
      },
      {
        path: '/offers-v2',
        name: 'OffersOverviewV2View',
        component: OffersOverviewV2View,
        beforeEnter: (to, from) => {
          if(!(isAdmin(app) || isSuperUser(app))) {
            return ({ name: 'Home' });
          }
        },
      },
      {
        path: '/users-v2',
        name: 'UserOverviewViewV2',
        component: UserOverviewViewV2,
        beforeEnter: (to, from) => {
          if(!isAdmin(app)) {
            return ({ name: 'Home' });
          }
        },
      },
      {
        path: '/contact-requests',
        name: 'ContactRequestsOverview',
        component: ContactRequestsOverview,
        beforeEnter: (to, from) => {
          if(!isAdmin(app)) {
            return ({ name: 'Home' });
          }
        },
      },
    ],
  });
  return router;
};

export {
  createRouter,
};
