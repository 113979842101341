export default {
  install: (app, store) => {
    // inject a globally available $i18n() method
    const translate = (key, textsToReplace = []) => {
      let translation = store.getTranslation(key);
      textsToReplace.forEach((text, index) => {
        translation = translation.replace(`{${index}}`, text);
      });
      return translation;
    };
    app.config.globalProperties.$i18n = translate;
    app.provide('i18n', translate );
  },
};
