<script setup>
import SddsToast from './SddsToast.vue';
import { useMainStore } from '../store';

const store = useMainStore();

</script>
<template>
  <template
    v-for="toast in store.toasts"
    :key="toast.id"
  >
    <SddsToast
      :id="toast.id"
      :type="toast.type"
      :header="toast.header"
      @dismiss="store.clearToast(toast.id)"
    />
  </template>
</template>
