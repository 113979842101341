<script setup>
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import scaniaLogo from '../assets/scania.svg';
import { useMainStore } from '../store/index.js';

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
});

const store = useMainStore();
const router = useRouter();
const isLanguageSelectionOpened = ref(false);
const isEnvSelectionOpened = ref(false);
const isMarketSelectionOpened = ref(false);

const selectedLanguage = computed(() => {
  return store.locale.substring(3, 5);
});

const setLanguage = (locale) => {
  store.locale = locale;
  store.fpcCodeTranslations = [];
  store.loadTranslations();
};

const selectedEnv = computed(() => {
  return store.envs.find(env => env.env === store.env).description;
});

const selectedMarket = computed(() => {
  return store.markets.find(env => env.marketId === store.market).description;
});

const setEnv = (env) => {
  if (store.env !== env.env) {
    window.location.href = env.url + router.currentRoute.value.path;
  }
};

const setMarket = (marketId) => {
  store.market = marketId;
};

</script>

<template>
  <nav class="sdds-nav sdds-nav--white">
    <div class="sdds-nav__left">
      <div class="sdds-nav__app-wordmark">
        <img
          :src="scaniaLogo"
          alt="Scania"
        >
      </div>
      <div
        v-if="props.title"
        class="sdds-nav__app-name"
      >
        {{ props.title }}
      </div>
    </div>

    <div
      v-if="store.env"
      class="sdds-banner sdds-banner-error sdds-u-w-100"
      :class="{ 'sdds-banner-green': store.env === 'dev' }"
    >
      <span class="sdds-banner-prefix sdds-u-pl2">
        <svg
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            y="0.334473"
            width="16"
            height="16"
          />
        </svg>
      </span>
      <div class="sdds-banner-body sdds-u-pl1">
        <h4 class="sdds-banner-header">
          This is {{ selectedEnv }} environment !
        </h4>
      </div>
    </div>

    <div class="sdds-nav__right">
      <ul class="sdds-nav__toolbar-menu">
        <li
          class="sdds-nav__item sdds-nav__dropdown"
          :class="{ 'sdds-nav__dropdown--opened': isMarketSelectionOpened }"
        >
          <button
            class="sdds-nav__item-core"
            @click="isMarketSelectionOpened = !isMarketSelectionOpened"
          >
            {{ selectedMarket }}
          </button>

          <ul class="sdds-nav__dropdown-menu">
            <li
              v-for="mark in store.markets"
              :key="mark.marketId"
              class="sdds-nav__dropdown-item"
            >
              <a
                href="#"
                class="sdds-nav__dropdown-item-core"
                @click="setMarket(mark.marketId);isMarketSelectionOpened=false"
              >
                {{ mark.description }}
              </a>
            </li>
          </ul>
        </li>
        <li
          class="sdds-nav__item sdds-nav__dropdown"
          :class="{ 'sdds-nav__dropdown--opened': isEnvSelectionOpened }"
        >
          <button
            class="sdds-nav__item-core"
            @click="isEnvSelectionOpened = !isEnvSelectionOpened"
          >
            {{ selectedEnv }}
          </button>

          <ul class="sdds-nav__dropdown-menu">
            <li
              v-for="env in store.envs"
              :key="env.env"
              class="sdds-nav__dropdown-item"
            >
              <a
                href="#"
                class="sdds-nav__dropdown-item-core"
                @click="setEnv(env);isEnvSelectionOpened=false"
              >
                {{ env.description }}
              </a>
            </li>
          </ul>
        </li>
        <li
          class="sdds-nav__item sdds-nav__dropdown"
          :class="{ 'sdds-nav__dropdown--opened': isLanguageSelectionOpened }"
        >
          <button
            class="sdds-nav__item-core"
            @click="isLanguageSelectionOpened = !isLanguageSelectionOpened"
          >
            {{ selectedLanguage }}
          </button>

          <ul class="sdds-nav__dropdown-menu">
            <li
              v-for="language in store.languages"
              :key="language.locale"
              class="sdds-nav__dropdown-item"
            >
              <a
                href="#"
                class="sdds-nav__dropdown-item-core"
                @click="setLanguage(language.locale);isLanguageSelectionOpened=false"
              >
                {{ language.description }}
              </a>
            </li>
          </ul>
        </li>
        <!-- <li
          class="sdds-nav__item sdds-nav__avatar"
        >
          <button
            class="sdds-nav__avatar-btn"
            @click="isLanguageSelectionOpened = !isLanguageSelectionOpened"
          >
            {{ selectedLanguage }}
          </button>

          <ul class="sdds-nav__avatar-menu">
            <li
              v-for="language in store.languages"
              :key="language.locale"
              class="sdds-nav__avatar-item"
            >
              <a
                href="#"
                class="sdds-nav__avatar-item-core"
                @click="setLanguage(language.locale);isLanguageSelectionOpened=false"
              >
                {{ language.description }}
              </a>
            </li>
          </ul>
        </li> -->
      </ul>
      <a
        class="sdds-nav__item sdds-nav__app-logo"
        href="#"
      />
    </div>
  </nav>
</template>

<style scoped>
  .sdds-nav--white {
    background-color: var(--sdds-white);
    border-bottom: 1px solid;
    border-color: var(--sdds-grey-200);
  }

    .sdds-nav__item { border-left: 1px solid; }
    .sdds-nav--white .sdds-nav__item { border-color: var(--sdds-grey-200); }
    .sdds-nav--white .sdds-nav__app-name { color: var(--sdds-blue); }
    .sdds-nav--white .sdds-nav__app-logo { background-color: var(--sdds-white); }
    .sdds-nav--white .sdds-nav__avatar-btn { background-color: var(--sdds-white); }

    .sdds-nav--white .sdds-nav__dropdown .sdds-nav__item-core {
      background-color: var(--sdds-white);
      color: black;
    }

  .sdds-nav__app-wordmark {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 24px;
    white-space: nowrap;
  }
    .sdds-nav__app-wordmark img {
      height: 1.7em;
    }
  .sdds-nav__app-logo {
    border-right: 0;
  }
  .sdds-banner {
    display: flex;
    align-items: center;
  }
  .sdds-banner-green {
    background-color: var(--sdds-green-100);
  }
  .sdds-banner-green .sdds-banner-prefix {
    color: var(--sdds-positive);
  }
</style>
