<script setup>
import { onMounted } from 'vue';

const emit = defineEmits(['dismiss']);

const props = defineProps({
  id: {
    type: String,
    default: '',
    required: true,
  },
  type: {
    type: String,
    default: '',
  },
  header: {
    type: String,
    default: '',
    required: true,
  },
  subheader: {
    type: String,
    default: '',
  },
  link: {
    type: Object,
    default: () => {
      return {};
    },
  },
  autohide: {
    type: Boolean,
    default: true,
  },
  duration: {
    type: Number,
    default: 5000,
  },
});

onMounted(() => {
  if(props.autohide) {
    setTimeout(() => {
      emit('dismiss', props.id);
    }, props.duration);
  }
});
</script>

<template>
  <div
    class="sdds-toast"
    :class="`sdds-toast-${type}`"
  >
    <div class="sdds-toast-icon">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      />
    </div>

    <div class="sdds-toast-content">
      <div class="sdds-toast-header">
        <span class="sdds-toast-headline">{{ header }}</span>
        <span
          class="sdds-toast-dismiss"
          @click="emit('dismiss', id)"
        />
      </div>

      <div
        v-if="subheader || link.url"
        class="sdds-toast-body"
      >
        <span
          v-if="subheader"
          class="sdds-toast-subheadline"
        >{{ subheader }}</span>
        <a
          v-if="link.url"
          class="sdds-toast-link"
          :href="link.url"
        >{{ link.text || link.url }}</a>
      </div>
    </div>
  </div>
</template>
