import { createApp } from 'vue';
import { defineCustomElements, addTheme } from '@scania/components';
import { theme as scania } from '@scania/theme-light';
import App from './App';
import { createPinia } from 'pinia';
import { createRouter } from './router';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import keycloakAuth from './plugins/keycloakAuth';
import { useMainStore } from './store';
import i18n from './plugins/i18n';

import { faSpinner, faXmark, faCheck, faArrowUp, faArrowDown, faCirclePlus, faCircleMinus } from '@fortawesome/free-solid-svg-icons';
library.add(faSpinner, faXmark, faCheck, faArrowUp, faArrowDown, faCirclePlus, faCircleMinus);

defineCustomElements();
addTheme(scania);

const app = createApp(App);

// Add v-event directive for handling custom events from SDDS components/StencilJS
// v-event:<custom event name>="<function>" for example
// For future reference, see https://github.com/vuejs/core/issues/5401
app.directive('event', {
  beforeMount(el, { arg, value }) {
    el.addEventListener(arg, value);
  },
  beforeUnmount(el, { arg, value }) {
    el.removeEventListener(arg, value);
  },
});

app.component('FontAwesomeIcon', FontAwesomeIcon);

app.use(createPinia());
app.use(keycloakAuth);

const kcInitOptions = {
  onLoad: 'login-required',
  pkceMethod: 'S256',
  checkLoginIframe: true,
};

app.$keycloak
  .init(kcInitOptions)
  .then((auth) => {
    if (!auth) {
      console.log('Not authenticated');
    } else {
      console.log('Authenticated');
      const store = useMainStore();
      app.use(i18n, store);
      store.token = app.$keycloak.token;
      console.log(store.token);
    }
  }).finally(() => {
    app.use(createRouter(app));
    app.mount('#app');
  });
